import React, { useRef, useEffect,useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { format } from "date-fns";
import { spacing } from '@material-ui/system';
import Typography from '@material-ui/core/Typography';
import Tooltip from './OverflowTooltip';
import theme from '../theme.js';

const ips = [
  '117.188.208.209',
  '16.187.237.36',
  '192.133.239.75',
 '78.146.101.61',
  '11.11.181.10',
  '206.20.141.23',
  '160.151.36.203',
  '6.253.86.208',
  '179.18.182.42',
  '85.61.59.193',
  '96.196.181.13',
  '98.52.66.175',
  '175.173.83.90',
  '228.11.220.75',
  '130.192.12.76',
  '218.248.11.120',
  '116.1.112.88',
  '5.156.33.86',
  '129.193.75.224',
  '239.154.128.215',
  '113.37.110.62',
  '205.63.28.167',
  '88.29.137.200',
  '192.101.140.170',
  '36.168.213.121',
  '85.235.17.98',
  '185.8.147.144',
  '223.145.88.102',
  '241.12.243.47',
  '223.104.203.227',
  '104.5.120.140',
  '107.91.23.184'
];

const userAgents = [
  'Mozilla/5.0 (Linux; Android 5.1; A1601 Build/LMY47I) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/66.0.3359.106 Mobile Safari/537.36',
  'Mozilla/5.0 (Linux; Android 4.4.2; Lenovo A536 Build/KOT49H) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/64.0.3282.186 YaBrowser/18.3.1.651.00 Mobile Safari/537.36',
  'Mozilla/5.0 (Linux; Android 5.1.1; KFSUWI Build/LVY48F) AppleWebKit/537.36 (KHTML, like Gecko) Silk/65.2.3 like Chrome/65.0.3325.144 Safari/537.36',
  'Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) coc_coc_browser/61.4.114 Chrome/55.4.2883.114 Safari/537.36',
  'Mozilla/5.0 (Linux; Android 6.0; LG-K580 Build/MRA58K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/64.0.3282.186 YaBrowser/18.3.1.651.00 Mobile Safari/537.36',
  'Mozilla/5.0 (iPhone; CPU iPhone OS 11_2_1 like Mac OS X) AppleWebKit/604.1.34 (KHTML, like Gecko) GSA/47.1.192149458 Mobile/15C153 Safari/604.1'
];

const useStyles = makeStyles((theme) => ({
  root: {
    // width/height of the corners (or 40px)
  },
   paper: {
    padding: theme.spacing(2),
    display: 'grid',
    overflow: 'auto',
    opacity:.25,
    transition:'opacity .25s',
    borderStyle: 'solid',
    borderWidth: '1px',
    boxShadow:'none',
    cursor: 'pointer',
    // special characters in data URIs have to be encoded, see the compiled CSS in the codepen below
    // (also do not wrap the lines, that's just for better uderstanding)
    borderImageSource: 'url("data:image/svg+xml;charset=utf8,%3Csvg xmlns=%22http:%2F%2Fwww.w3.org%2F2000%2Fsvg%22 viewBox=%220 0 30 30%22%3E%3Crect x=%220.5%22 y=%220.5%22 width=%2229%22 height=%2229%22 fill=%22transparent%22 stroke=%22%23575865%22 stroke-width=%221%22 %2F%3E%3C%2Fsvg%3E")',
    borderImageSlice: '50%', // slice the image to 4 parts through the middle
    borderImageWidth: '15px', // width/height of the corners (or 40px)

    '&:hover':{
        opacity:.5,
        transition:'opacity .25s',
    }
  },

   fixedHeight: {
    // minHeight: '300px',
    [theme.breakpoints.down('sm')]: {
       minHeight: '140px',
    },
    //  [theme.breakpoints.down('lg')]: {
    //    minHeight: '240px',
    // },
  },
  img:{
    width:'100%',
     [theme.breakpoints.down('md')]: {
      // maxWidth:'100px'
       // width: '75px',
       // display:'none'
    },

  },
  active:{
    opacity:1.0,
    transition:'opacity .25s',
    '&:hover':{
        opacity:1.0
    }
  },
  innerType:{
    // [theme.breakpoints.down('lg')]: {
        fontSize:'10px',
        paddingTop:'.3rem',
      // },
      height:'100%'
      // fontSize:'12px',
     // paddingTop:'.3rem',
  },
  type:{
    // display:'grid',
    width:'100%',
    overflow:'hidden',
    paddingLeft:'.5rem',
    // paddingTop:'.5rem',

  },
  empty:{

  },
  card:{
    width: '100%',
    overflow: 'hidden',
    padding: theme.spacing(4),
    background: '#595A6B',
    borderRadius:'0px',
    height:'100%',
    boxShadow: '0px 8px 18px rgba(0, 0, 0, 0.12)'
    // filter: 'dropShadow(0px 8px 18px rgba(0, 0, 0, 0.12))'
  },
  cardActive:{
    boxShadow: '0px 8px 18px rgba(0, 0, 0, 0.12)'
  }
}));
function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}

    function dataLine (data,style,variant="body2"){
      return data &&
      <Grid item>
      <Tooltip title={data} style={style} noWrap variant={variant}/>
      </Grid>
    }


export default function TargetDetailCard({ selected,style, target, handleClick,size, i}) {
  console.log(target);
  const classes = useStyles(theme);
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
    let formatted_date = isValidDate(new Date(target.Date)) ? format(new Date(target.Date),"MM/dd/yy Hmma") : null;
    return(
    <Box px={1.5} pb={3} style={style} onClick={handleClick}>
      <Box className={clsx(fixedHeightPaper, selected && classes.active)} style={{minHeight: `${size-20}px`}}>
        <Paper className={clsx(classes.card, selected && classes.cardActive)} >
          <Grid container spacing={2} >
            <Grid xs={3} md={4} item>
                <img className={classes.img}  src={target.profile ? target.profile : 'default.jpeg'}/>
            </Grid>
            <Grid item xs={9} md={8} className={classes.type} >
              {dataLine(target['Full Name'],classes.empty,"body1")}
              {dataLine(target['Phone Number'],classes.innerType)}
              {dataLine(target.Email,classes.innerType)}
              {dataLine(target.City,classes.innerType)}
              {dataLine(target.State,classes.innerType)}
              {dataLine(target.ISP,classes.innerType)}
              {/*dataLine(target.Lat,classes.innerType)}
              {dataLine(target.Long,classes.innerType)*/}
              {dataLine(target['Remote IP'] || ips[i] || '241.12.243.47',classes.innerType)}
              {dataLine(target['User Agent'] || userAgents[Math.floor(Math.random() * userAgents.length)] ,classes.innerType)}
              {dataLine(target.device,classes.innerType)}
              {dataLine(target.browser,classes.innerType)}
              {dataLine(formatted_date,classes.innerType)}
            </Grid>
          </Grid>
          </Paper>
       </Box>
     </Box>
    );
};
